import React from "react";
import ContactBlock from "./ContactBlock";
import FooterContactBlock from "components/Footer/FooterContactBlock";
import { contactContent } from "staticContent/contact";
import { departments } from "staticContent/footer";

const companyContacts = contactContent.contactData.map((contact, index) => (
  <ContactBlock {...contact} key={index} />
));

const companyLegalConfirmation = contactContent.legalConfirmation.map(info => (
  <p key={info}>{info}</p>
));

const departmentsList = departments.map(department => (
  <div key={department.title}>
    <FooterContactBlock content={department} />
  </div>
));

const ContactCompanyData = ({ pageWidth }) => (
  <div className="contact__company-data">
    <h2 className="contact__company-title">{contactContent.companyName}</h2>
    {companyContacts}
    <div className="contact__block contact__block--legal-numbers">
      {companyLegalConfirmation}
    </div>
    <p className="contact__block contact__block--legal-info">
      {contactContent.legalNotice}
    </p>
    {pageWidth < 900 && (
      <div className="contact__block contact__block--departments">
        {departmentsList}
      </div>
    )}
  </div>
);

export default ContactCompanyData;
