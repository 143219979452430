import axios from "axios";
import showModal from "./popupModal";
import { contactContent } from "staticContent/contact";

const createNewMail = async (mailForm, reset) => {
  return axios
    .post("/hv-form-access", mailForm)
    .then(() => {
      showModal(contactContent.modalInfo.success, "success");
      Object.keys(mailForm).forEach(entry => reset(entry));
    })
    .catch(error => {
      console.error(error);
      showModal(contactContent.modalInfo.error, "error");
    })
    .then(() => false);
};

export default createNewMail;
