import React from "react";
import { renderContact } from "helpers/contact.helpers";

const ContactBlock = ({ description, data }) => {
  const showContact = renderContact(data, 'contact__block--line');

  return (
    <div className="contact__block" key={description}>
      <h3 className="contact__block--title">{description}</h3>
      {showContact}
    </div>
  );
};

export default ContactBlock;
