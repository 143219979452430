import React from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import ContactFormInput from "./ContactFormInput";
import { access } from "../../api/reCaptcha";
import { Button } from "utils";
import { contactContent } from "staticContent/contact";
import createNewMail from "services/createNewMail";
import { modalMessage } from "helpers/contact.helpers";

const recaptchaRef = React.createRef();
const { title, placeholder, name, mail, shortNote, formKey, btn } =
  contactContent.formContent;

const onSubmit = (data, reset) => {
  const recaptchaValue = recaptchaRef.current.getValue();
  const emailCheck = /\S+@\S+\.\S+/;

  Object.keys(data).forEach(entry => !data[entry] && delete data[entry]);
  if (!emailCheck.test(data.email)) {
    modalMessage(contactContent.modalInfo, "info");
    return;
  }

  recaptchaValue
    ? createNewMail(data, reset)
    : modalMessage(contactContent.modalInfo, "info", "captcha");
};

const ContactForm = () => {
  const { register, handleSubmit, reset } = useForm();

  return (
    <div className="contact__form">
      <form className="form" onSubmit={handleSubmit(onSubmit, reset)}>
        <h2 className="form__title">{title}</h2>
        <ContactFormInput
          type={formKey.name}
          title={name}
          placeholder={placeholder.input}
          register={register}
        />
        <ContactFormInput
          type={formKey.mail}
          title={mail}
          placeholder={placeholder.input}
          register={register}
        />
        <ContactFormInput
          type={formKey.message}
          title={shortNote}
          placeholder={placeholder.textarea}
          register={register}
        />
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={access.client}
          className="form__captcha"
        />
        <Button children={btn} className="form__btn" />
      </form>
    </div>
  );
};

export default ContactForm;
