import React, { useEffect, useState } from "react";
import { contactContent } from "staticContent/contact";
import "styles/components/hero.scss";
import "styles/components/contact.scss";
import ContactForm from "./ContactForm";
import ContactCompanyData from "./ContactCompanyData";

const Contact = () => {
  const [pageWidth, setPageWidth] = useState(0);

  useEffect(() => {
    setPageWidth(window.screen.width);
  }, []);

  return (
    <section className="section hero contact">
      <h1 className="hero__banner-text">{contactContent.pageTitle}</h1>
      <div className="contact__wrapper">
        <ContactCompanyData pageWidth={pageWidth} />
        <ContactForm />
      </div>
      <p className="contact__gdpr-info">{contactContent.gdpr}</p>
    </section>
  );
};

export default Contact;
