import React from "react";

const ContactFormInput = ({ type, title, placeholder, register }) => (
  <label className="form__single-block">
    <p className="form__paragraph">{title}</p>
    {type !== "message" ? (
      <input
        className="form__input"
        type={type}
        placeholder={placeholder}
        required
        {...register(type)}
      />
    ) : (
      <textarea
        className="form__input form__input--textarea"
        placeholder={placeholder}
        required
        minLength={10}
        {...register(type)}
      ></textarea>
    )}
  </label>
);

export default ContactFormInput;
