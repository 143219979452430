import React from "react";
import Contact from "components/Contact";
import Layout from "utils/Layout";
import { contactContent } from "staticContent/contact";

const IndexPage = () => (
  <Layout subTitle={contactContent.pageTitle}>
    <Contact />
  </Layout>
);

export default IndexPage;
