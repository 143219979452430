const contactContent = {
  pageTitle: "Kontakt",
  companyName: "HyperView\n sp. z o.o.",
  contactData: [
    {
      description: "Dział Wsparcia Technicznego:",
      data: ["+48 32 420 73 96"],
    },
    {
      description: "Dział Marketingu:",
      data: ["+48 32 420 73 89"],
    },
    {
      description: "Siedziba:",
      data: ["ul.Chorzowska 50", "44-100 Gliwice", "+48 32 420 73 54", "sekretariat@hyperview.pl"],
    },
    {
      description: "Oddział:",
      data: ["ul. Nałęczowska 30", "20-701 Lublin", "+48 81 511 40 24"],
    },
  ],
  legalConfirmation: ["KRS: 0000537819", "NIP: 6342834160", "Regon: 360225711"],
  legalNotice:
    "Zarejestrowana przez Sąd Rejonowy w Gliwicach, X Wydział Gospodarczy Krajowego Rejestru Sądowego",
  formContent: {
    title: "Napisz do nas",
    placeholder: {
      input: "pole wymagane",
      textarea: "wiadomość musi zawierać minimum 10 znaków",
    },
    name: "Imię i nazwisko",
    mail: "e-mail",
    shortNote: "Jak możemy Ci pomóc?",
    btn: "wyślij",
    formKey: {
      name: "name",
      mail: "email",
      message: "message",
    },
  },
  modalInfo: {
    success:
      "Wiadomość została wysłana! 📫\n Wkrótce się z Tobą skontaktujemy 📢",
    error:
      "Nie udało się wysłac wiadomości 🤷\n Spróbuj ponownie lub wybierz inną formę kontaktu 🕊️",
    info: "Nieprawidłowy adres email 📧",
    captcha: "Musisz jeszcze udowodnić, że nie jesteś robotem 🤖",
  },
  gdpr: `Zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016) zwanym dalej RODO informuję, iż:, 1) administratorem Pani/Pana danych osobowych jest HyperView sp. z o.o., ul.Chorzowska 50 44-100 Gliwice 2) Pani/Pana dane osobowe przetwarzane będą w celu nawiązania kontaktu na podstawie Art. 6 ust. 1 lit. f RODO jako usprawiedliwionego interesu administratora, 3) odbiorcami Pani/Pana danych osobowych będą wyłącznie podmioty uprawnione do uzyskania danych osobowych na podstawie przepisów prawa oraz podmioty przetwarzające zarządzające stroną www, 4) Pani/Pana dane osobowe przechowywane będą przez okres 2 lat, 5) posiada Pani/Pan prawo do żądania od administratora dostępu do danych osobowych, prawo do ich sprostowania usunięcia lub ograniczenia przetwarzania, 6) ma Pani/Pan prawo wniesienia skargi do organu nadzorczego, 7) podanie danych osobowych jest dobrowolne, jednakże niepodanie danych może skutkować niemożliwością nawiązania kontaktu`,
};

export { contactContent };
